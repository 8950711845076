import React, {useCallback, useState} from "react";
import {useParams, useNavigate} from "react-router-dom";
import useSWR from "swr";
import {
    deployLiveAgent,
    fetchAgentLogs,
    fetchArtifactFilesWithUrl,
    getAgentTransactions,
    getLiveAgentById
} from "../../api/hasura";
import {PageContentContainer} from "../styled/styled";
import {Flex, Text, Button, Card, Tabs, Box, Callout, Spinner, Table, IconButton} from "@radix-ui/themes";
import {ArrowLeftIcon, ExclamationTriangleIcon, OpenInNewWindowIcon} from "@radix-ui/react-icons";
import {useAppState} from "../../state/AppStateProvider";
import {AgentStatus} from "../../components/AgentStatus";
import {formatMonetaryValue, formatPubKey} from "../../utils/commonUtils";
import {openTransaction} from "../wallets/WalletDetails";
import {DashboardMetric} from "./AgentsOverview";

interface Agent {
    id: string;
    name: string;
    status: string;
    artifact_id_version_id: string;
    created_at: string;
    config: {
        agent_config: any;
    };
    pnl: {
        pnlInUsd: number;
        pnlInPercentage: number;
    }
}

export const AgentDetails = () => {

    const {setSnackBar} = useAppState();
    const {id} = useParams();
    const navigate = useNavigate();


    const [isDeploying, setIsDeploying] = useState(false);
    const [displayedAgentLogs, setDisplayedAgentLogs] = useState<any>([]);
    const [nextPageToken, setNextPageToken] = useState<string | null>(null);
    const [isLoadingMore, setIsLoadingMore] = useState(false);


    const {
        data: agentDetails,
        isLoading,
        mutate: mutateAgent
    } = useSWR<Agent>(
        id ? `agents/${id}` : null,
        () => getLiveAgentById(id as string)
    );

    //where will agent transactions be?
    // const isTransactionsLoading = isLoading;

    // const agentTransactions = [
    //     {
    //         tx_name: "Mock Transaction",
    //         transaction_hash: "0xf2689e691bd8a04e57fdce6228bfe5fd02699757b84bbad899eda353fd4bd9dd",
    //         chain_id: "42161",
    //         gas_spent_eth: "0.00000420",
    //         gas_spent_usd: "0.69"
    //     },
    // ];

    const {
        data: agentTransactions,
        isLoading: isTransactionsLoading,
    } = useSWR(`agent-${id}-transactions`, () => getAgentTransactions(id as string));


    console.log("agentTransactions", agentTransactions);
    console.log("agent", agentDetails);

    const {
        data: agentLogsData,
        isLoading: isLogsLoading,
        mutate: mutateAgentLogs,
        error: agentLogsError,
    } = useSWR(
        `${agentDetails?.id}-logs`,
        agentDetails?.id ? () => fetchAgentLogs(agentDetails?.id) : null,
        {
            onSuccess: (data) => {
                if (data) {
                    setDisplayedAgentLogs((prevState: any) => ([...prevState, ...data.logs]));
                    setNextPageToken(data.nextPageToken);
                }
            },
        }
    );

    const loadMoreLogs = useCallback(async () => {
        if (!nextPageToken || !id) {
            console.error("No page token or no ID");
            return;
        }

        setIsLoadingMore(true);
        try {
            const nextPageData = await fetchAgentLogs(id, nextPageToken);
            setDisplayedAgentLogs((prevLogs: any) => [...prevLogs, ...nextPageData.logs]);
            setNextPageToken(nextPageData.nextPageToken);
        } catch (error) {
            console.error("Error loading more logs:", error);
        } finally {
            setIsLoadingMore(false);
        }
    }, [agentDetails?.id, nextPageToken]);


    console.log("agentLogsData", agentLogsData);
    console.log("agentLogsError", agentLogsError);
    const agentLogs = agentLogsData?.logs;


    // const {
    //     data: strategyFiles
    // } = useSWR("strategy-templates", fetchArtifactFilesWithUrl())

    if (isLoading) {
        return (
            <PageContentContainer>
                <Flex direction="column" align="center" gap="3" py="8">
                    <Text size="3" weight="medium" color="gray">
                        Loading Agent Details
                    </Text>
                    <Spinner size="2"/>
                </Flex>
            </PageContentContainer>
        );
    }

    if (!agentDetails) {
        return (
            <PageContentContainer>
                <Text>Agent not found</Text>
            </PageContentContainer>
        );
    }


    const onDeployAgent = async () => {

        try {
            setIsDeploying(true);

            const deployedAgent = await deployLiveAgent(agentDetails.id);

            console.log("deployedAgent", deployedAgent);

            if (deployedAgent.valid) {
                setSnackBar({open: true, message: deployedAgent.message, severity: "success"});
            } else {
                setSnackBar({open: true, message: `${deployedAgent.message.substr(0, 150)}...`, severity: "error"});
            }
        } catch (e) {
            console.error("Error deploying agent", e);
            setSnackBar({open: true, message: "Error deploying agent", severity: "error"});
        } finally {
            setIsDeploying(false);
            mutateAgent();
        }

    }

    const agentPnlUsd = agentDetails?.pnl?.pnlInUsd;
    const agentPnlPercentage = agentDetails?.pnl?.pnlInPercentage;

    return (
        <PageContentContainer>

            <Flex direction="column" gap="6">
                <Flex justify="between" align="center">
                    <Flex gap={"16px"}>
                        <Button variant={"outline"} onClick={() => navigate("/agents")}>
                            <ArrowLeftIcon/>Back</Button>
                        <Text size="6" weight="bold">Agent Details</Text>
                    </Flex>

                    <>
                    </>
                </Flex>

                <Flex gap="4" wrap="wrap">
                    {agentPnlUsd && <DashboardMetric
                        label="PnL (USD)"
                        value={agentPnlUsd}
                        isProfitLoss={true}
                        isUsdValue
                    />}
                    {agentPnlPercentage && <DashboardMetric isProfitLoss={true} isPercentage label="PnL %" value={agentPnlPercentage}/>}
                </Flex>


                {agentDetails.status === "PENDING" &&
                    <Flex direction={"row"} align={"center"} style={{gap: "16px"}}>

                        <Callout.Root color="yellow">
                            <Flex align={"center"} gap={"8px"}>
                                <Callout.Icon>
                                    <ExclamationTriangleIcon/>
                                </Callout.Icon>
                                <Callout.Text>
                                    <Flex justify={"between"} align={"center"} gap={"64px"}>
                                        <span>Agent is pending deployment.</span>
                                        <Button disabled={isDeploying} onClick={onDeployAgent}>

                                            Deploy Agent
                                            {isDeploying && <Spinner/>}
                                        </Button>
                                    </Flex>
                                </Callout.Text>
                            </Flex>

                        </Callout.Root>

                    </Flex>
                }

                <Card>
                    <Flex direction="column" gap="4" p="4">
                        <Flex justify="between" align="center">
                            <Text size="2" color="gray">Agent Name</Text>
                            <Text size="2">{agentDetails.name || 'Unnamed Agent'}</Text>
                        </Flex>

                        <Flex justify="between" align="center">
                            <Text size="2" color="gray">Strategy Version ID</Text>
                            <Text size="2" style={{fontFamily: 'monospace'}}>
                                {agentDetails.artifact_id_version_id}
                            </Text>
                        </Flex>

                        <Flex justify="between" align="center">
                            <Text size="2" color="gray">Created At</Text>
                            <Text size="2">
                                {new Date(agentDetails.created_at).toLocaleString()}
                            </Text>
                        </Flex>

                        <Flex justify="between" align="center">
                            <Text size="2" color="gray">Status</Text>
                            <AgentStatus status={agentDetails.status}/>
                        </Flex>
                    </Flex>
                </Card>

                <Tabs.Root defaultValue="logs">
                    <Tabs.List>
                        {/*<Tabs.Trigger value="dashboard">Dashboard</Tabs.Trigger>*/}
                        <Tabs.Trigger value="logs">Logs</Tabs.Trigger>
                        <Tabs.Trigger value="transactions">Transactions</Tabs.Trigger>
                        <Tabs.Trigger value="configuration">Configuration</Tabs.Trigger>

                        {/*<Tabs.Trigger value="wallet">Wallet</Tabs.Trigger>*/}
                    </Tabs.List>

                    <Box pt="4">
                        <Tabs.Content value="dashboard">
                            <Text>Dashboard content coming soon...</Text>
                        </Tabs.Content>

                        <Tabs.Content value="configuration">
                            <Card>
                                <pre style={{
                                    padding: '16px',
                                    overflow: 'auto',
                                    backgroundColor: 'var(--gray-2)',
                                    borderRadius: '6px'
                                }}>
                                    {agentDetails.config?.agent_config
                                        ? JSON.stringify(agentDetails.config.agent_config, null, 2)
                                        : 'No configuration available'}
                                </pre>
                            </Card>
                        </Tabs.Content>

                        <Tabs.Content value="transactions">
                            {isTransactionsLoading ? (
                                <><Spinner/><Text>Loading transactions...</Text></>
                            ) : (
                                <Table.Root>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.ColumnHeaderCell>Name</Table.ColumnHeaderCell>
                                            <Table.ColumnHeaderCell>TX Hash</Table.ColumnHeaderCell>
                                            <Table.ColumnHeaderCell>Gas Spent</Table.ColumnHeaderCell>
                                            <Table.ColumnHeaderCell></Table.ColumnHeaderCell>
                                        </Table.Row>
                                    </Table.Header>

                                    <Table.Body>
                                        {!agentTransactions || agentTransactions?.length === 0 ? (
                                            <Table.Row>
                                                <Table.Cell colSpan={5}>No transactions found</Table.Cell>
                                            </Table.Row>
                                        ) : (
                                            agentTransactions?.map((tx: any) => (
                                                <Table.Row
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                    key={tx.transaction_hash}
                                                    onClick={() => openTransaction(tx)}
                                                >
                                                    <Table.Cell>{tx.tx_name ?? "-"}</Table.Cell>
                                                    <Table.Cell>{formatPubKey(tx.transaction_hash)}</Table.Cell>
                                                    <Table.Cell>{tx.gas_spent_eth} (${formatMonetaryValue(tx.gas_spent_usd)})</Table.Cell>
                                                    <Table.Cell><IconButton
                                                        variant={"ghost"}><OpenInNewWindowIcon/></IconButton></Table.Cell>
                                                </Table.Row>
                                            ))
                                        )}
                                    </Table.Body>
                                </Table.Root>
                            )}
                        </Tabs.Content>


                        <Tabs.Content value="logs">
                            {!agentLogsError && !agentLogsData &&
                                <Flex direction="row" align="center" justify="center" gap="3" py="8">
                                    Loading <Spinner size="2"/>
                                </Flex>
                            }


                            {!isLogsLoading && agentLogsError && <Callout.Root color="red">
                                <Flex align={"center"} gap={"8px"}>
                                    <Callout.Text>
                                        Error fetching logs! {agentLogsError?.message}
                                    </Callout.Text>
                                </Flex>
                            </Callout.Root>
                            }

                            {!isLogsLoading && !agentLogsError && agentLogs?.length !== 0 && <Card>
                                <Table.Root variant="ghost">
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.ColumnHeaderCell>Time</Table.ColumnHeaderCell>
                                            <Table.ColumnHeaderCell>Message</Table.ColumnHeaderCell>
                                        </Table.Row>
                                    </Table.Header>

                                    <Table.Body>
                                        {displayedAgentLogs?.map((log: any, index: number) => (
                                            <Table.Row key={index}>
                                                <Table.Cell>{new Date(log.timestamp).toLocaleTimeString()}</Table.Cell>
                                                <Table.Cell>{log.message}</Table.Cell>
                                            </Table.Row>
                                        ))}
                                    </Table.Body>


                                </Table.Root>
                                {nextPageToken && <Flex style={{margin: "16px"}} justify={"center"} align={"center"} width={"100%"}>
                                    <Button variant="outline" loading={isLoadingMore} onClick={loadMoreLogs}>Load More...</Button>
                                </Flex>}
                            </Card>}
                            {!isLogsLoading && displayedAgentLogs?.length === 0 &&
                                <Callout.Root color="gray">
                                    <Flex align={"center"} gap={"8px"}>
                                        <Callout.Text>
                                            No logs available!
                                        </Callout.Text>
                                    </Flex>
                                </Callout.Root>
                            }

                        </Tabs.Content>

                        <Tabs.Content value="wallet">
                            <Text>Wallet content coming soon...</Text>
                        </Tabs.Content>
                    </Box>
                </Tabs.Root>
            </Flex>
        </PageContentContainer>
    )
        ;
};

import * as React from "react";
import {PageContentContainer} from "../views/styled/styled";
import {CenteredRowFlexBox, FlexCol} from "../views/simulation/create-simulation/styled";
import Box from "@mui/material/Box";
import {Alert, CircularProgress, MenuItem, Modal, Select, Tooltip} from "@mui/material";
import {ArrowBackIosNew} from "@mui/icons-material";
import {useNavigate, useParams} from "react-router-dom";
import CodeMirror from "@uiw/react-codemirror";
import {useEffect, useState} from "react";
import {useAppState} from "../state/AppStateProvider";
import {python} from '@codemirror/lang-python';
import {ArtifactVersionItem} from "../views/strategy/ArtifactVersionItem";
import useSWR from "swr";
import {fetchArtifactFilesWithUrl, getArtifactLibraryItems, toggleStrategyAccess} from "../api/hasura";
import {LibraryArtifact, LibraryArtifactVersion} from "./StrategyLibraryWidget";
import {formatDateValue} from "../views/simulation/create-simulation/PoolDetails";
import Chip from "@mui/material/Chip";

import {Badge, Button, DropdownMenu, Flex, IconButton, Spinner} from "@radix-ui/themes";
import {
    ArrowLeftIcon,
    ChevronDownIcon,
    ChevronLeftIcon,
    DotsHorizontalIcon,
    ExitIcon,
    PersonIcon
} from "@radix-ui/react-icons";
import {Select as RadixSelect, Card} from "@radix-ui/themes";
import {AlmanakModalContent} from "../modals/ScenarioCustomizationModal";
import CloseIcon from "@mui/icons-material/Close";
import {formatPubKey} from "../utils/commonUtils";

export const StrategyLibraryDetailsWidget = () => {

    const navigate = useNavigate();
    let {artifactId} = useParams();


    const {theme, user, setSnackBar} = useAppState();

    const {
        data: artifactList,
        mutate
    } = useSWR("strategy-templates", getArtifactLibraryItems)


    const artifact = artifactList?.find((s: LibraryArtifact) => s.id === artifactId);
    console.log("artifact", artifact);

    const isUserAuthor = artifact?.author === user?.id;
    console.log("user", user);
    console.log("isUserAuthor", isUserAuthor);
    const [artifactVersion, setArtifactVersion] = useState<LibraryArtifactVersion>(artifact?.latest_version_artifact);
    const [code, setCode] = useState<any>(null);
    const [selectedFile, setSelectedFile] = useState(artifactVersion?.artifact_files?.[0]);

    const [isChangeAccessModalOpen, setIsChangeAccessModalOpen] = useState(false);
    const [isChangeAccessLoading, setIsChangeAccessLoading] = useState(false);

    console.log("selectedFile", selectedFile);

    const artifactName = artifact?.name;
    const {
        data: artifactFilesWithUrl,
    } = useSWR(artifactVersion ? `${artifactVersion?.id}-files` : null, () => fetchArtifactFilesWithUrl(artifactName, artifactVersion?.name));


    console.log("selectedFile", selectedFile);
    console.log("selectedFile artifactFilesWithUrl", artifactFilesWithUrl);
    // const selectedFileUrl = artifactFilesWithUrl?.find()

    const selectedFileAuthenticatedUri = artifactFilesWithUrl?.find((f: any) => f.id === selectedFile.id).presigned_url;
    const {
        data: selectedFileContents,
        isLoading: fileLoading,
    } = useSWR(selectedFileAuthenticatedUri, async () => {
        const res = await fetch(selectedFileAuthenticatedUri)
        return res.text()
    });


    const onVersionChange = (version: string) => {
        const selectedVersion = artifact?.artifact_versions?.find((s: LibraryArtifact) => s.id === version);
        setArtifactVersion(selectedVersion);

        setSelectedFile(selectedVersion?.artifact_files?.[0])
    }

    const toggleAccessChange = async () => {
        setIsChangeAccessModalOpen(false);
        setIsChangeAccessLoading(true);

        try {
            const res = await toggleStrategyAccess(artifact?.id, !artifact?.is_public);
            console.log("res", res);
            setSnackBar({
                open: true,
                message: "Strategy is now " + (res.is_public ? "Public" : "Private"),
                severity: "success"
            });
        } catch (e: any) {
            console.error("error", e);
            setSnackBar({
                open: true,
                message: `Error changing access: ${e?.message}`,
                severity: "error"
            });
        } finally {
            await mutate()
            setIsChangeAccessLoading(false);
        }
    }

    return <PageContentContainer>

        <Box>

        </Box>
        <Flex justify={"between"} align={"center"}>
            <Flex align={"center"} gap={"16px"}>
                <Button variant="outline"
                        onClick={() => navigate(-1)}
                ><ArrowLeftIcon/>Back</Button>
                <h2>Strategy details</h2>
            </Flex>
            {isUserAuthor && <DropdownMenu.Root>
                <DropdownMenu.Trigger>
                    <Button variant={"soft"} loading={isChangeAccessLoading}>
                        Settings <ChevronDownIcon/>
                    </Button>
                </DropdownMenu.Trigger>
                <DropdownMenu.Content style={{minWidth: "144px"}}>
                    <DropdownMenu.Item onClick={() => setIsChangeAccessModalOpen(true)}>
                        Make {artifact?.is_public ? "Private" : "Public"}
                    </DropdownMenu.Item>
                </DropdownMenu.Content>
            </DropdownMenu.Root>}

        </Flex>


        <Box sx={{display: "flex", gap: 4}}>

            <Box>
                <div style={{padding: "16px 0 8px 0", fontSize: "14px", fontWeight: "bold"}}>Name</div>
                <span style={{fontSize: "14px"}}>{artifact?.name}</span>
            </Box>

            <Box>
                <div style={{padding: "16px 0 8px 0", fontSize: "14px", fontWeight: "bold"}}>Creation Date</div>
                <span style={{fontSize: "14px"}}>{formatDateValue(artifact?.date_created)}</span>
            </Box>

            <Box>
                <div style={{padding: "16px 0 8px 0", fontSize: "14px", fontWeight: "bold"}}>Access</div>
                <Badge color={artifact?.is_public ? "green" : "gray"} radius={"full"} size={"1"}>
                    {artifact?.is_public ? "Public" : "Private"}
                </Badge>
            </Box>
        </Box>

        <Box sx={{display: "flex", gap: 4}}>


            <Box>
                <div style={{padding: "16px 0 8px 0", fontSize: "14px", fontWeight: "bold"}}>Description</div>
                <span
                    style={{fontSize: "14px"}}>{!!artifact?.description ? artifact.description : "No Description available"}</span>
            </Box>

        </Box>

        <Box>
            {artifact?.metadata?.tags && <Box>
                <div style={{padding: "16px 0 8px 0", fontSize: "14px", fontWeight: "bold"}}>Tags</div>
                <CenteredRowFlexBox sx={{gap: "8px"}}>
                    {artifactVersion.metadata?.tags.map((tag: string) => <Chip label={tag}/>)}
                </CenteredRowFlexBox>
            </Box>}
        </Box>


        {/*{isUserAuthor && <FlexCol sx={{gap: "16px"}}>*/}
        {/*    <Box>*/}
        {/*        <Box sx={{my: 2}} style={{fontSize: "14px", fontWeight: "bold"}}>Author Controls</Box>*/}

        {/*        <Button loading={isChangeAccessLoading} onClick={() => setIsChangeAccessModalOpen(true)}*/}
        {/*                size={"1"}>Make {artifact?.is_public ? "Private" : "Public"}</Button>*/}
        {/*    </Box>*/}
        {/*</FlexCol>}*/}

        <FlexCol sx={{gap: "16px"}}>
            <Box>
                <Box sx={{my: 2}} style={{fontSize: "14px", fontWeight: "bold"}}>Strategy Versions</Box>
                <RadixSelect.Root defaultValue={artifactVersion?.id}>
                    <RadixSelect.Trigger/>
                    <RadixSelect.Content>
                        {artifact?.artifact_versions?.map((version: LibraryArtifactVersion) =>
                            <RadixSelect.Item
                                value={version.id}>
                                {version.name} - {formatDateValue(version.date_created)}
                            </RadixSelect.Item>
                        )}
                    </RadixSelect.Content>
                </RadixSelect.Root>

                {/*<Select value={artifactVersion?.id} onChange={e => onVersionChange(e.target.value)} size={"small"}>*/}
                {/*    {artifact?.artifact_versions?.map((version: LibraryArtifactVersion) => <MenuItem*/}
                {/*        value={version.id}>{version.name} - {formatDateValue(version.date_created)}</MenuItem>)}*/}
                {/*</Select>*/}
            </Box>


            <h2>Version Details</h2>

            {artifactVersion &&
                <ArtifactVersionItem artifact={artifact} key={artifact.id} artifactVersion={artifactVersion}
                                     isDetails/>}

            {artifactVersion?.artifact_files?.length > 0 && <>
                <h2>Strategy Files</h2>
                <Box sx={{display: "flex", minHeight: "420px"}}>

                    <Box sx={{display: "flex", flexDirection: "column", maxWidth: "180px"}}>

                        {artifactVersion?.artifact_files?.map(file => {
                            const fileName = file?.uri?.split("/")?.pop();


                            return <Tooltip placement={"right"} title={fileName}><Button
                                onClick={() => setSelectedFile(file)}
                                radius={"none"}
                                style={{whiteSpace: "nowrap", overflow: "hidden"}}
                                variant={file.id === selectedFile?.id ? "solid" : "outline"}
                                value={"python"}>{fileName}</Button>
                            </Tooltip>
                        })}

                    </Box>
                    {!selectedFileContents && fileLoading &&
                        <Box sx={{display: "flex", m: 4, gap: "16px"}}>
                            <div>Loading file contents</div>
                            <div><Spinner/></div>
                        </Box>
                    }
                    {selectedFileContents &&
                        <Box style={{width: "100%", marginLeft: "8px"}}>
                            <CodeMirror readOnly={true} maxWidth={"60vw"} value={selectedFileContents} theme={theme}
                                        extensions={[python()]}/>
                        </Box>}

                    {!selectedFileContents && !fileLoading &&
                        <Box sx={{display: "flex", m: 4, gap: "16px"}}>
                            <div>No file contents available</div>
                        </Box>
                    }
                </Box>
            </>}

        </FlexCol>


        {isChangeAccessModalOpen &&
            <Modal
                sx={{display: "flex"}}
                open={isChangeAccessModalOpen}
                onClose={() => setIsChangeAccessModalOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >


                <AlmanakModalContent>
                    <Box sx={{
                        p: 3,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        fontWeight: "bold"
                    }}>
                        Make strategy {artifact?.is_public ? "Private" : "Public"}
                        <IconButton variant="outline"
                                    onClick={() => setIsChangeAccessModalOpen(false)}><CloseIcon/></IconButton>
                    </Box>

                    <Alert severity={"info"} sx={{m: 1}}>
                        You are about to make this strategy {artifact?.is_public ? "Private" : "Public"}.
                    </Alert>

                    <Box sx={{display: "flex", gap: 1, p: 3, justifyContent: "flex-end"}}>
                        <Button style={{borderRadius: "4px", padding: "4px 12px"}} variant="soft"
                                onClick={() => setIsChangeAccessModalOpen(false)}>Cancel</Button>
                        <Button style={{borderRadius: "4px", padding: "4px 12px"}} color="green"
                                onClick={toggleAccessChange}>Make {artifact?.is_public ? "Private" : "Public"} </Button>
                    </Box>

                </AlmanakModalContent>
            </Modal>}

    </PageContentContainer>

}

import * as React from "react";
import {PageContentContainer} from "../views/styled/styled";
import {CenteredRowFlexBox, FlexBox, FlexCol} from "../views/simulation/create-simulation/styled";
import {Alert, CircularProgress, InputAdornment} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import {useNavigate} from "react-router-dom";
import {LDKeyStrategyLibraryMaintenance, useAppState} from "../state/AppStateProvider";
import useSWR from "swr";
import {getArtifactLibraryItems} from "../api/hasura";
import Fuse from "fuse.js";
import {useEffect, useState} from "react";
import {ArtifactItem} from "../views/strategy/ArtifactItem";
import Box from "@mui/material/Box";
import {LDKeyStrategyLibraryWidget} from "../components/layout/Sidebar";
import {Callout, IconButton, Spinner, Table, Tabs, Text, TextField} from "@radix-ui/themes";
import {ExclamationTriangleIcon, MagnifyingGlassIcon, OpenInNewWindowIcon} from "@radix-ui/react-icons";
import {Card} from "@radix-ui/themes";
import {formatMonetaryValue} from "../views/simulation/create-simulation/PoolDetails";
import {formatPubKey} from "../utils/commonUtils";
import {openTransaction} from "../views/wallets/WalletDetails";

// Fuse.js configuration
const options = {
    includeScore: true,
    threshold: 0.2,
    // Fields to search in
    keys: ['author_user.email', 'author_user.name', 'latest_version_artifact.name', 'metadata.input_features', 'metadata.output_structure', 'metadata?.tags', 'name', 'description'],
};

export type ArtifactFile = {
    id: string;
    uri: string
    authenticated_url: string;
    date_created: string;
    description: string;
}

export type LibraryArtifactVersion = {
    artifact_files: ArtifactFile[],
    author: string,
    date_created: string,
    description: string,
    id: string,
    is_public: boolean,
    metadata: any,
    name: string,
    type: string,
    author_user: { email: string, name: string | null }
    chain_id?: string;
}
export type LibraryArtifact = {
    latest_version_artifact: LibraryArtifactVersion,
    artifact_versions: LibraryArtifactVersion[],
    author: string,
    date_created: string,
    description: string,
    id: string,
    is_public: boolean,
    latest_public_version: string | null,
    metadata: any,
    name: string,
    type: string,
    author_user: { email: string, name: string | null }
}
export const StrategyLibraryWidget = () => {

    // const formController = useForm<StrategyLibraryForm>({
    //         defaultValues: {},
    //         reValidateMode: "onChange"
    //     }
    // );
    const navigate = useNavigate();

    const {featureFlags, user} = useAppState();
    const isStrategyLibraryMaintenanceMode = featureFlags?.[LDKeyStrategyLibraryMaintenance];
    const isStrategyLibraryEnabled = featureFlags?.[LDKeyStrategyLibraryWidget];

    const [searchValue, setSearchValue] = useState("");

    const [selectedTab, setSelectedTab] = useState("all");

    const {
        data: artifactList,
        isLoading,
    } = useSWR("strategy-templates", getArtifactLibraryItems)

    const openDetails = (strategy: LibraryArtifact) => {
        navigate(`/strategy-library/${strategy.id}`)
    }

    console.log("artifactList", artifactList);

    const userStrategies = artifactList?.filter((artifact: LibraryArtifact) => artifact.author === user?.id);

    const [displayedArtifacts, setDisplayedArtifacts] = React.useState<LibraryArtifact[]>(artifactList);

    const [searcher, setSearcher] = useState(new Fuse(artifactList ?? [], options))

    const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const query = e.target.value;

        setSearchValue(query);

        if (query === "") {
            setDisplayedArtifacts(artifactList);
            return;
        }
        const result = searcher.search(query);
        setDisplayedArtifacts(result.map((r: any) => r.item));
    }

    const onTabChange = (tab: string) => {
        setSelectedTab(tab as 'all' | 'private');
        const initialArtifacts = tab === "all" ? artifactList : userStrategies;
        setDisplayedArtifacts(initialArtifacts);
    }


    useEffect(() => {
        setDisplayedArtifacts(artifactList);
        const newSearcher = new Fuse(artifactList ?? [], options)
        setSearcher(newSearcher)
    }, [artifactList]);


    if (!isStrategyLibraryEnabled) {
        return <PageContentContainer>
            <Box sx={{m: 4, display: "flex", justifyContent: "center", alignItems: "center", height: "100%"}}>
                <h2>Strategy library feature is coming soon!</h2>
            </Box>
        </PageContentContainer>
    }


    return <PageContentContainer>


        <CenteredRowFlexBox sx={{gap: "16px", justifyContent: "space-between"}}>
            <h2>Strategies</h2>
        </CenteredRowFlexBox>

        <Box>

            {isStrategyLibraryMaintenanceMode &&
                <Callout.Root color="red">
                    <Callout.Icon>
                        <ExclamationTriangleIcon/>
                    </Callout.Icon>
                    <Callout.Text>
                        Strategy Library is currently in maintenance mode. Unexpected errors may occur.
                    </Callout.Text>
                </Callout.Root>
            }

            <Box sx={{my: 2, maxWidth: "420px"}}>
                <TextField.Root placeholder="Find strategies..." value={searchValue}
                                onChange={onSearch}>
                    <TextField.Slot>
                        <MagnifyingGlassIcon />
                    </TextField.Slot>
                </TextField.Root>
            </Box>


            <Tabs.Root style={{marginBottom: "8px"}} defaultValue="all" value={selectedTab}
                       onValueChange={(value: string) => onTabChange(value as 'all' | 'private')}>
                <Tabs.List>
                    <Tabs.Trigger value="all">All Strategies</Tabs.Trigger>
                    <Tabs.Trigger value="private">My strategies</Tabs.Trigger>
                </Tabs.List>
            </Tabs.Root>

            {/*<TextField*/}
            {/*    InputProps={{*/}
            {/*        startAdornment: (*/}
            {/*            <InputAdornment position="start">*/}
            {/*                <SearchIcon/>*/}
            {/*            </InputAdornment>*/}
            {/*        )*/}
            {/*    }}*/}
            {/*    onChange={onSearch}*/}
            {/*    size={"small"} variant={"outlined"} placeholder="Search by name, description, tags etc..."*/}
            {/*    sx={{width: "100%", maxWidth: "400px"}}/>*/}

            {displayedArtifacts?.length === 0 &&
                <Callout.Root color="blue">
                    <Callout.Icon>
                        <ExclamationTriangleIcon/>
                    </Callout.Icon>
                    <Callout.Text>
                        No strategies found. Please try a different search.
                    </Callout.Text>
                </Callout.Root>
            }

        </Box>
        {isLoading && <FlexBox sx={{justifyContent: "center", alignItems: "center", gap: 2, m: 4}}>
            Loading Strategies <Spinner/>
        </FlexBox>
        }
        <Card style={{padding: 0}}>
            {displayedArtifacts?.map((artifact: LibraryArtifact) =>
                <ArtifactItem key={artifact?.id} artifact={artifact}/>
            )}
        </Card>
    </PageContentContainer>

}

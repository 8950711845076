import {LibraryArtifact} from "../../widgets/StrategyLibraryWidget";
import Box from "@mui/material/Box";
import React from "react";
import {CenteredRowFlexBox, PanelBox} from "../simulation/create-simulation/styled";
import {formatDateValue} from "../simulation/create-simulation/PoolDetails";
import Chip from "@mui/material/Chip";
import {Badge, Button, Card, Flex, Text} from "@radix-ui/themes";
import {useNavigate} from "react-router-dom";
import {ChainBadge} from "../wallets/ChainBadge";


export const ArtifactItem = ({artifact, isDetails}: { artifact: LibraryArtifact, isDetails?: boolean }) => {

    const navigate = useNavigate();


    const openArtifactDetails = () => {
        //console.log("item", artifact);
        navigate(`/strategy-library/${artifact.id}`)
    };

    return <Box sx={{borderBottom: "1px solid var(--gray-6)"}}>
        <Box sx={{p: 2}}>
            <Box sx={{display: "flex", alignItems: "center", gap: 1}}>
                <Button variant="ghost" onClick={openArtifactDetails}>
                    <span className={"title"}>{artifact.name}</span>
                </Button>

                <Badge style={{marginLeft: "8px"}} color={artifact?.is_public ? "green" : "gray"} radius={"full"} size={"1"}>
                    {artifact.is_public ? "Public" : "Private"}
                </Badge>

                {/*<span>{formatDateValue(artifact.date_created)}</span>*/}
            </Box>

            {artifact.description?.length > 0 && <Box>
                <span style={{fontSize: "14px"}}>{artifact.description}</span>
            </Box>}


            {artifact.author_user && <Box>
                <CenteredRowFlexBox sx={{gap: "16px"}}>
                    {artifact.author_user.name && <span style={{fontSize: "14px"}}>{artifact.author_user.name}</span>}
                    {artifact.author_user.email && <span style={{fontSize: "14px"}}>{artifact.author_user.email}</span>}
                </CenteredRowFlexBox>
            </Box>}


            <Flex direction={"row"} align={"center"} gap={"2"} style={{marginTop: "16px"}}>
                {artifact.latest_version_artifact?.metadata?.chain_id && <ChainBadge chainId={artifact.latest_version_artifact.metadata.chain_id}></ChainBadge>}
                {/*<ChainBadge chainId={"42161"}></ChainBadge>*/}
                {artifact.latest_version_artifact?.name && <Badge size={"3"}>
                    {artifact.latest_version_artifact?.name}
                </Badge>}

                <Text size={"2"}>
                    Created {formatDateValue(artifact.date_created)}
                </Text>
            </Flex>

            {/*<Box sx={{display: "flex", justifyContent: "space-between", alignItems: "flex-end"}}>*/}
            {/*    <Box>*/}
            {/*        {artifact.metadata?.tags && <Box>*/}
            {/*            <div style={{padding: "16px 0 8px 0", fontSize: "14px", fontWeight: "bold"}}>Tags</div>*/}
            {/*            <CenteredRowFlexBox sx={{gap: "8px"}}>*/}
            {/*                {artifact.metadata?.tags.map((tag: string) => <Badge>{tag}</Badge>)}*/}
            {/*            </CenteredRowFlexBox>*/}
            {/*        </Box>}*/}
            {/*    </Box>*/}
            {/*    /!*{!isDetails && <Button onClick={openArtifactDetails}>Show Details</Button>}*!/*/}
            {/*</Box>*/}
        </Box>


    </Box>
}

import Safe from "@safe-global/protocol-kit";
import {ALMANAK_AGENT_ROLE_KEY, ALMANAK_AGENT_ROLE_NAME} from "./WalletCreationFlow";
import {applyMembers} from "zodiac-roles-sdk";
import {allow, apply} from "defi-kit/eth";

export const getApplyRoleTRX = async (zodiacModuleAddress: string, permissions?: any) => {

    const EURE = '0xcB444e90D8198415266c6a2724b7900fb12FC56E'
    const USDC = '0xddafbb505ad214d7b80b1f830fccc89b60fb7a83'

    const perms = await allow.cowswap.swap({buy: [EURE, USDC], sell: [EURE, USDC]})
    const deafaultPermissions = [
        perms
    ]

    //TODO: don't use default permissions. this is temporary as permissions returned by API does not work
    const usedPermissions = deafaultPermissions ?? permissions;
    const calls = await apply(ALMANAK_AGENT_ROLE_KEY, usedPermissions, {
        address: zodiacModuleAddress as `0x${string}`,
        mode: 'replace',
        log: console.debug
    })
    return calls;
}

export  const getDelegateRoleTRX = async (zodiacModuleAddress: string, chainId: any, roleName: string, members: `0x${string}`[]) => {

    const calls = await applyMembers(
        ALMANAK_AGENT_ROLE_KEY,
        members,
        {
            chainId: chainId,
            address: zodiacModuleAddress as `0x${string}`,
            mode: "replace",
        }
    );
    const transactions = calls.map(data => ({to: zodiacModuleAddress, value: '0', data}));

    return transactions;

}

export const delegateRole = async (provider: any, almanakEOAAddress: string, safeAddress: string, chainId: string) => {

    if (!provider) {
        console.error('provider not initialized!');
        return

    }

    console.log("delegateRole start", provider, almanakEOAAddress, safeAddress, chainId);
    const protocolKit = await Safe.init({provider: provider, safeAddress: safeAddress});


    const modules = await protocolKit.getModules();
    console.log("delegateRole delegateRole modules", modules);
    const delegateRolTrx = await getDelegateRoleTRX(modules[0], chainId, ALMANAK_AGENT_ROLE_NAME, [almanakEOAAddress as `0x${string}`]);

    console.log("delegateRolTrx", delegateRolTrx);

    const safeTransaction = await protocolKit.createTransaction({transactions: delegateRolTrx})
    const txResponse = await protocolKit.executeTransaction(safeTransaction)

    return txResponse;


}

import Box from "@mui/material/Box";
import * as React from "react";
import {useTheme, Typography, Button, Card, CardContent} from "@mui/material";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import {PageContentContainer} from "../styled/styled";
import {Link} from "react-router-dom";

export const SuccessPurchase = () => {
    const theme = useTheme();

    return (
        <PageContentContainer>
            <Box sx={{padding: theme.spacing(4), textAlign: 'center'}}>
                <Box sx={{
                    maxWidth: 600,
                    margin: '0 auto',
                    padding: theme.spacing(4),
                    borderRadius: "8px"
                }}>
                    <Box>
                        <CheckCircleOutlineIcon sx={{fontSize: 60, color: '#2e7d32', marginBottom: theme.spacing(2)}}/>
                        <Typography variant="h4" sx={{mb: 4, fontWeight: 'bold'}}>
                            Purchase Successful!
                        </Typography>
                        <Typography variant="body1" sx={{marginBottom: theme.spacing(4)}}>
                            Thank you for your purchase. Your credits have been added to your account.
                        </Typography>

                        <Link to={"/home"}>
                            <Button variant="contained">
                                Back to Dashboard
                            </Button>
                        </Link>
                    </Box>
                </Box>
            </Box>
        </PageContentContainer>
    );
};

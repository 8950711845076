import {Component, ErrorInfo, ReactNode} from "react";
import Box from "@mui/material/Box";
import {Button, styled} from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline"
interface Props {
    children?: ReactNode;
}

interface State {
    hasError: boolean;
    message?: string;
}

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  min-height: 160px;
  gap: 16px;
  font-size: 18px;
`

class ErrorBoundary extends Component<Props, State> {
    public state: State = {
        hasError: false
    }

    static getDerivedStateFromError(error: Error) {
        // Update state so the next render will show the fallback UI.
        return {hasError: true, message: error.message};
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        //implement proper logging here
        console.info("Error boundary caught an error", error, errorInfo)
    }

    render() {
        if (this.state.hasError) {

            console.error("Error boundary caught an error!", this.state.message)
            // return <Container>
            //     <div style={{fontWeight: "bold"}}>An unexpected error occurred:</div>
            //     <div>{this.state.message}</div>
            // </Container>
            return <Container sx={{
                m: 4,
                flexDirection: "column",
                display: "flex",
                minHeight: "200px",
                justifyContent: "center",
                alignItems: "center"
            }}>
                <Box sx={{display: "flex", alignItems: "center", gap: "16px"}}><ErrorOutlineIcon sx={{color: "red", width: "24px", height: "24px", m: 2}}/>
                    <h4>Oops! There was an error.</h4>
                </Box>
                <h4>{this.state.message}</h4>
                <Button variant="contained" onClick={() => window.location.replace("/home")}>Go Home</Button>
            </Container>
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
